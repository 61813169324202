var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('the-wrapper',{attrs:{"isSpinner":_vm.isSpinner}},[_c('base-service',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Состояние услуги «Звонок с сайта»")]},proxy:true},{key:"body",fn:function(){return [(_vm.rules.length)?_c('base-table',{attrs:{"fields":_vm.fields,"items":_vm.rules},scopedSlots:_vm._u([{key:"headerText",fn:function(){return [_vm._v("Список правил обратного вызова")]},proxy:true},{key:"cell(PhoneNumber)",fn:function(ref){
var item = ref.item;
return [_c('b-table',{attrs:{"fields":_vm.fields,"items":item},scopedSlots:_vm._u([{key:"cell(PhoneNumber)",fn:function(ref){
var el = ref.el;
return [_vm._v(" "+_vm._s(el)+" ")]}}],null,true)})]}},{key:"cell(StartDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.StartDate.replace(/[T]/, ' '))+" ")]}},{key:"cell(Actions)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
              name: 'EditCallbackRule',
              query: { id: item.Id }
            }}},[_vm._v(" Редактировать ")]),_c('br'),_c('br'),_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.deleteSelectedRule(item.Id)}}},[_vm._v(" Удалить ")])]}}],null,false,120474520)}):_c('base-table',{scopedSlots:_vm._u([{key:"headerText",fn:function(){return [_vm._v(" Список правил обратного вызова ")]},proxy:true}])},[_vm._t("default",[_vm._v(" Настроенные правила обратного вызова отсутствуют ")])],2),_c('br'),_c('BaseButton',{on:{"click":_vm.goToCreate},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v("Добавить правило")]},proxy:true}])}),_c('br'),_c('br'),_c('div',{class:_vm.$style.infoWidget},[_c('info-block',{scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('info-link',{on:{"clickLink":function($event){_vm.visible = !_vm.visible}}},[_vm._v(" Как разместить услугу на своем сайте? ")])]},proxy:true}])}),_c('b-collapse',{staticStyle:{"margin-top":"0.5rem"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('widget-code')],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }