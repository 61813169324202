<template>
  <ServicesDescription :isSubtitle="true" :isPageWrapper="true" @connectService="connectService">
    <template #title>
      <h2>Услуга «Звонок с сайта»</h2>
    </template>
    <template #subtitle>
      Используйте новый источник привлечения клиентов!
    </template>
    <template #body>
      <h4>Установите на сайт специальный виджет обратного звонка.</h4>
      <br />
      <text-list :text="text"></text-list>
      <br />
      <h5>Как это работает?</h5>
      <div>
        <b-row v-for="item in howItWork" :key="item.step" :class="$style.stepItem">
          <b-col xl="5" :class="$style.stepItemText" class="dflex valign-center">
            <div :class="$style.text">
              {{ item.text }}
            </div>
          </b-col>
          <b-col xl="2" :class="$style.stepNumberWrap" class="dflex align-center valign-center">
            <div :class="$style.stepNumber" class="dflex align-center valign-center">
              {{ item.step }}
            </div>
          </b-col>
          <b-col xl="5"></b-col>
        </b-row>
      </div>
      <br />
      <span>
        <b>Попробуйте сами!</b>
      </span>
      <p>
        Нажмите на кнопку "Заказать звонок" и наш специалист свяжется с вами - кнопка «Заказать
        звонок» в шапке личного кабинета (звонок в нашу компанию)
      </p>
    </template>
  </ServicesDescription>
</template>

<script>
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import TextList from '@/components/shared/TextList.vue';

export default {
  data() {
    return {
      text: [
        'Вы получите новых клиентов в один клик',
        'Сотрудникам не нужно будет искать контакты, оставившего заявку. Система, автоматически, направит звонок на свободного оператора',
        'Система мгновенно соединит специалиста с клиентом, пока он заинтересован товаром или готов совершить покупку',
        'Номера клиентов автоматически сохранятся в Личном кабинете или CRM-системе'
      ],
      howItWork: [
        {
          text:
            'На Вашем сайте устанавливается специальная кнопка "Позвонить", "Заказать звонок" или др.',
          step: 1,
          offset: '0'
        },
        {
          text:
            'Посетитель сайта нажимает на неё и виджет автоматически направляет звонок по выбранной Вами схеме',
          step: 2,
          offset: '5'
        },
        {
          text:
            'После поднятия трубки специалист услышит специальный сигнал, определяющий, что звонок произошел с сайта',
          step: 3,
          offset: '0'
        }
      ]
    };
  },

  components: {
    ServicesDescription,
    TextList
  },

  methods: {
    connectService() {
      this.$router.push({
        path: '/create-statement',
        query: { st: 'addService', service: 'звонок-с-сайта' }
      });
    }
  }
};
</script>

<style module lang="scss">
.stepNumber {
  width: 3.75rem;
  height: 3.75rem;
  font-size: 2rem;
  border-radius: 50%;
  border: 3px solid $red;
  color: $red;
}

.stepItem {
  max-width: 25rem;
}

.stepItemText {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  order: 1;
}

@media (min-width: 1200px) {
  .stepItem {
    margin-left: 1rem;
    max-width: 43.75rem;
  }

  .stepItemText {
    order: 0;
  }

  .stepItem:nth-child(2) > .stepItemText {
    order: 2;
  }

  .stepItem:nth-child(2) .stepNumberWrap {
    order: 1;
  }
}
</style>
