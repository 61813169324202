<template>
  <component :is="isConnected"></component>
</template>

<script>
import CallbackDescription from '@/components/services/call_back/CallbackDescription.vue';
import CallbackConnected from '@/components/services/call_back/CallbackConnected.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    CallbackDescription,
    CallbackConnected
  },

  computed: {
    ...mapGetters({ servicesData: 'connectedServices/servicesData' }),

    isConnected() {
      return this.servicesData.Callback === 2 ? 'CallbackConnected' : 'CallbackDescription';
    }
  }
};
</script>

<style></style>
