<template>
<the-wrapper :isSpinner="isSpinner">
  <base-service>
    <template #title>Состояние услуги «Звонок с сайта»</template>
    <template #body>
      <base-table v-if="rules.length" :fields="fields" :items="rules">
        <template #headerText>Список правил обратного вызова</template>
        <template #cell(PhoneNumber)="{item}">
          <b-table :fields="fields" :items="item">
            <template #cell(PhoneNumber)="{el}">
              {{ el }}
            </template>
          </b-table>
        </template>
        <template #cell(StartDate)="{item}">
          {{ item.StartDate.replace(/[T]/, ' ') }}
        </template>
        <template #cell(Actions)="{item}">
          <router-link
            :to="{
              name: 'EditCallbackRule',
              query: { id: item.Id }
            }"
          >
            Редактировать
          </router-link>
          <br />
          <br />
          <a href="" @click.prevent="deleteSelectedRule(item.Id)">
            Удалить
          </a>
        </template>
      </base-table>
      <base-table v-else>
        <template #headerText> Список правил обратного вызова </template>
        <slot> Настроенные правила обратного вызова отсутствуют </slot>
      </base-table>
      <br />
      <BaseButton @click="goToCreate">
        <template #text>Добавить правило</template>
      </BaseButton>
      <br />
      <br />
      <div :class="$style.infoWidget">
        <info-block>
          <template #text>
            <info-link @clickLink="visible = !visible">
              Как разместить услугу на своем сайте?
            </info-link>
          </template>
        </info-block>
        <b-collapse v-model="visible" style="margin-top: 0.5rem">
          <widget-code></widget-code>
        </b-collapse>
      </div>
    </template>
  </base-service>
  </the-wrapper>
</template>

<script>
import BaseService from '@/components/shared/BaseService.vue';
import BaseTable from '@/components/shared/BaseTable.vue';
import InfoLink from '@/components/shared/InfoLink.vue';
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import InfoBlock from '@/components/shared/InfoBlock.vue';

import { getServiceData, sendCustomerRequestDelete } from '@/api/common';
import { catchFunction, processResponse } from '@/additional_helpers/helpers';
import { CALL_BACK, DELETE_URL } from '@/api/callback';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      isSpinner: true,
      visible: false,
      rules: [],
      fields: [
        {
          key: 'CustomerAni',
          label: 'Номер телефона, который отобразится, как номер вызывающего абонента (АОН)'
        },
        {
          key: 'OperatorPhoneNumber',
          label: 'Номер телефона оператора, на который реально будет направлен вызов'
        },
        { key: 'Key', label: 'Название группы правил' },
        { key: 'EnabledDaysOfWeek', label: 'Дни недели, во время которых правило действует' },
        { key: 'StartTime', label: 'Время начала действия правила' },
        { key: 'EndTime', label: 'Время окончания действия правила' },
        { key: 'StartDate', label: 'Дата, время обращения' },
        { key: 'EndDate', label: 'Дата время конца работы правила' },
        { key: 'Email', label: 'Адрес для отправки уведомления' },
        { key: 'Actions', label: '' }
      ],
      test: [
        {
          key: 'CustomerAni',
          label: ''
        }
      ]
    };
  },

  components: {
    BaseService,
    BaseTable,
    BaseButton,
    InfoLink,
    InfoBlock,
    TheWrapper,
    WidgetCode: () => import('@/components/services/call_back/WidgetCode.vue')
  },

  created() {
    getServiceData(CALL_BACK)
      .then((result) => {
        if (result.length) this.rules.push(...result);
      })
      .then(() => this.SHOW_LOADING(false))
      .catch((e) => catchFunction(e));
  },

  methods: {
    ...mapMutations({ SHOW_LOADING: 'SHOW_LOADING' }),

    deleteSelectedRule(id) {
      sendCustomerRequestDelete(DELETE_URL, '', `?id=${id}`)
        .then((result) => {
          processResponse(result);
          if (result.status === 200) {
            this.rules = this.rules.filter((el) => el.Id !== id);
          }
        })
        .catch((e) => catchFunction(e));
    },

    goToCreate() {
      this.$router.push({
        name: 'CreateCallbackRule'
      });
    }
  }
};
</script>

<style module>
.infoWidget {
  padding: 1rem 1rem 2rem 1rem;
  background: #fff;
}

@media (min-width: 576px) {
  .infoWidget {
    padding: 1rem;
  }
}
</style>
